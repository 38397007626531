// import VueRouter from 'vue-router'
import {createRouter,createWebHashHistory} from 'vue-router'



const RouterList = [
    {
        path: '/',
        name:'home',
        component: () => import('@/views/index/index')
    },
    {
        path: '/pay',
        name:'pay',
        component: () => import('@/views/pay/index')
    },
    {
        path: '/clientpay',
        name:'clientpay',
        component: () => import('@/views/pay/ClientPayment.vue')
    },
    {
        path: '/pay/success',
        name:'success',
        component: () => import('@/views/pay/SuccessPage')
    }
]

const Routers = createRouter({
    history:createWebHashHistory(),
    routes:RouterList
})
export  {
    Routers
}


// const createRouter = () => new VueRouter({
//     routes: RouterList
// });
//
//
// const router = createRouter()
//
//
//
// export default router
