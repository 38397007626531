import {getSiteData} from "@/api/home";

const state = {
};

const mutations = {
};

const actions = {
    getsitedata(_,data){
        return new Promise((resolve, reject) => {
            getSiteData(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
