import request from '@/utils/request'

export function getSiteData(hostname) {
  return request({
    url: '/paymentsite',
    method: 'get',
    params:{
      hostname:hostname
    }
  })
}
