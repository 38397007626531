import Vuex from 'vuex'
import home from "@/store/modules/home";
import pay from "@/store/modules/pay";


const store = new Vuex.Store({
  modules: {
    home,
    pay
  }
});

export default store
