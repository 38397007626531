import { createApp } from 'vue'
import App from './App.vue'
import Vant from 'vant';
import {Routers} from '@/routor'
import Store from '@/store/index'

import 'vant/lib/index.css';


createApp(App).use(Routers).use(Vant).use(Store).mount('#app')

