import {
    getOrderPaymentDetail,
    getWechatpayUrl,
    getWechatQrcode,
    getClientPaymentDetail,
    getAlipayUrl} from "@/api/pay";

const state = {
};

const mutations = {
};

const actions = {
    getclientpaymentdetail(_,data){
        return new Promise((resolve, reject) => {
            getClientPaymentDetail(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getorderpaymentdetail(_,data){
        return new Promise((resolve, reject) => {
            getOrderPaymentDetail(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getwechatqrcode(_,data){
        return new Promise((resolve, reject) => {
            getWechatQrcode(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getalipayurl(_,data){
        return new Promise((resolve, reject) => {
            getAlipayUrl(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getwechatpayurl(_,data){
        return new Promise((resolve, reject) => {
            getWechatpayUrl(data).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
