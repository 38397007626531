import request from '@/utils/request'


export function getClientPaymentDetail(data) {
    return request({
        url: '/payment/getclientpaymentdetail',
        method: 'get',
        params:data
    })
}
export function getOrderPaymentDetail(data) {
    return request({
        url: '/payment/getpaymentorderdetail',
        method: 'get',
        params:data
    })
}

// export function getWechatQrcode(data) {
//     return request({
//         url: '/payment/newwechatpayment',
//         method: 'get',
//         params:data
//     })
// }
export function getWechatpayUrl(data) {
    return request({
        url: '/payment/newwechatpayment',
        method: 'get',
        params:data
    })
}

export function getAlipayUrl(data) {
    return request({
        url: '/payment/newalipaypayment',
        method: 'get',
        params:data
    })
}
